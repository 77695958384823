import React, {useState} from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Inicio from "./components/Inicio";
import './App.css';
import Landing from "./components/Landing";

const App = () => {
    const [usuario, setUsuario] = useState({
        username: "",
        password: "",
        role: 0,
    });

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path='/'
                    element={<Inicio setUsuario={setUsuario}/>}
                />
                <Route
                    path='/home'
                    element={<Landing usuario={usuario}/>}
                />
            </Routes>
        </BrowserRouter>
    );
};

export default App;