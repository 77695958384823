import React, {useEffect, useState} from 'react';
import {Nav, Tab, Container, Table, Col, Row, Form, Button} from 'react-bootstrap';
import {useApiService} from "../../hooks/useApiService";
import {useNavigate} from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Landing = ({usuario}) => {

    const [activeTab, setActiveTab] = useState('venta');
    const [productsTable, setProductsTable] = useState([{
        id: 0
    }]);

    // Para reportes
    const [productsSale, setProductsSale] = useState([]);
    const [productStockReport, setProductStockReport] = useState([]);
    const [salesReport, setSalesReport] = useState([]);
    const [userSalesSummary, setUserSalesSummary] = useState([]);
    const [salesReportByOperator, setSalesReportByOperator] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState("");
    const [selectedEndDate, setSelectedEndDate] = useState("");
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(0);
    const [stocks, setStocks] = useState([]);
    const [selectedStock, setSelectedStock] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [product, setProduct] = useState({
        id: 0,
        name: "",
        description: "",
        cost: 0,
        price: 0,
        stock_quantity: 0,
    });
    const [saleDetail, setSaleDetail] = useState({
        saleID: 0,
        productID: 0,
        quantity: 0,
    });
    const [newSale, setNewSale] = useState(false);
    const [saleDetails, setSaleDetails] = useState([]);

    const { sendPOST, sendPUT, sendGET } = useApiService();

    const handleNewSale = () => {
        const formData = {
            userID: usuario.id
        }

        sendPOST("sales", formData).then((result) => {
            setSaleDetail(prevState => {
                return {
                    ...prevState,
                    saleID: result.id,
                }
            });
            setSaleDetails([]);
            setNewSale(true);
        }).catch(() => {});
    }

    const handleNewSaleDetail = () => {
        console.log(saleDetail);
        if(saleDetail.quantity !== 0){
            sendPOST("sale-details", saleDetail).then(() => {
                getSaleDetail();
            }).catch(() => {});
        }
    }

    const getSaleDetail = () => {
        sendGET("sale-details/" + saleDetail.saleID).then((result) => {
            setSaleDetails(result);
        }).catch(() => {});
    }

    const getProducts = () => {
        sendGET("products").then((result) => {
            setProductsTable(result);
            if(result[0] !== undefined)
                setProduct(result[0])
        }).catch(() => {});
    }

    const getProductsSale = () => {
        sendGET("products-sales").then((result) => {
            setProductsSale(result);
        }).catch(() => {});
    }

    const getProductStockReport = () => {
        sendGET("product-stock-report").then((result) => {
            setProductStockReport(result);
        }).catch(() => {});
    }

    const getSalesReport = () => {
        const data = {
            startDate: selectedStartDate,
            endDate: selectedEndDate
        }

        sendPOST("sales-report", data).then((result) => {
            setSalesReport(result);
        }).catch(() => {});
    }

    const getUserSalesSummary = () => {
        sendGET("user-sales-summary/" + selectedUser).then((result) => {
            setUserSalesSummary(result);
        }).catch(() => {});
    }

    const getUsers = () => {
        sendGET("users").then((result) => {
            setUsers(result);
            setSelectedUser(result[0].id)
        }).catch(() => {});
    }

    const getSalesReportByOperator = () => {
        sendGET("sales-report-by-operator/" + usuario.id).then((result) => {
            setSalesReportByOperator(result);
        }).catch(() => {});
    }

    const getStocks = () => {
        sendGET("stocks").then((result) => {
            setStocks(result);
            if(result[0] !== undefined)
                setSelectedStock(result[0]);
        }).catch(() => {});
    }

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleStockSelectChange = (event) => {
        setSelectedStock(
            stocks.filter(stock => stock.id.toString() === event.target.value)[0]
        );
    }

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    const handleDateSearch = () => {
        getSalesReport();
    }

    const handleUserChange = (event) => {
        setSelectedUser(event.target.value);
    }

    const handleQuantityChange = (event) => {
        setSaleDetail(prevState => {
            return {
                ...prevState,
                quantity: event.target.value,
            }
        });
        setQuantity(event.target.value);
    }

    const handleUserSalesSearch = () => {
        getUserSalesSummary();
    }

    const handleAdd = () => {
        const formData = {
            quantityToAdd: quantity
        }

        sendPUT("add-stock/" + selectedStock.id, formData).then(() => {
            getStocks();
            setQuantity(0);
        }).catch(() => {});
    }

    const handleSubtract = () => {
        const formData = {
            quantityToSubtract: quantity
        }

        sendPUT("subtract-stock/" + selectedStock.id, formData).then(() => {
            getStocks();
            setQuantity(0);
        }).catch(() => {});
    }

    const handleProductChange = (event) => {
        const filteredProduct = productsTable.filter(product => product.id.toString() === event.target.value);
        if(filteredProduct[0] !== undefined)
            setProduct(filteredProduct[0]);
    }

    const handleSaveProduct = () => {
        sendPUT("products/" + product.id, product).then(() => {
            getProducts();
            if(productsTable[0] !== undefined)
                setProduct(productsTable[0]);
        }).catch(() => {});
    }

    const handleProductEdit = (event) => {
        setProduct(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value,
            }
        })
    }

    const handleDetailProductChange = (event) => {
        setSaleDetail(prevState => {
            return {
                ...prevState,
                productID: event.target.value,
            }
        });
    }

    useEffect(() => {
        getProducts();
        getProductsSale();
        getProductStockReport();
        getUsers();
        getStocks();
        getSalesReportByOperator();
    }, [activeTab]);

    useEffect(() => {
        if(productsTable[0] !== undefined) {
            setSaleDetail(prevState => {
                return {
                    ...prevState,
                    productID: productsTable[0].id,
                }
            });
        }
    }, [productsTable])

    const [newProduct, setNewProduct] = useState({
        name: "",
        description: "",
        cost: "",
        price: "",
    });

    const handleNewProductChange = (event) => {
        setNewProduct(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }

    const handleSaveNewProduct = () => {
        sendPOST("products", newProduct).then(() => {
            getProducts();
            if(productsTable[0] !== undefined)
                setProduct(productsTable[0]);
            setNewProduct({
                name: "",
                description: "",
                cost: "",
                price: "",
            })
        }).catch(() => {});
    }


    return (
        <Container>
            <Tab.Container activeKey={activeTab}>
                <Nav fill variant="pills" onSelect={(key) => setActiveTab(key)}>
                    <Nav.Item>
                        <Nav.Link eventKey="venta">Venta</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="productos">Informacion de Productos</Nav.Link>
                    </Nav.Item>
                    {
                        usuario.role === 1 ? (
                            <>
                                <Nav.Item>
                                    <Nav.Link eventKey="adminProductos">Admin. de productos</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="adminBodega">Admin. de bodega</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="repRotacion">Rep. rotacion</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="repProductos">Rep. productos</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="repVentasFecha">Rep. ventas por fecha</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="repVentasOperador">Rep. ventas por operador</Nav.Link>
                                </Nav.Item>
                            </>
                        ) : (
                            <>
                                <Nav.Item>
                                    <Nav.Link eventKey="repVentas">Mi reporte de ventas</Nav.Link>
                                </Nav.Item>
                            </>
                        )
                    }
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="venta">
                        <h3>Area de Ventas</h3>
                        <Button onClick={handleNewSale}>Nueva venta</Button>
                        {
                            newSale && (
                                <>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Producto</Form.Label>
                                                <select className={"form-control"} onChange={handleDetailProductChange} value={saleDetail.productID}>
                                                    {
                                                        productsTable.map((product, index) => (
                                                            <option key={index} value={product.id}>
                                                                {product.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Cantidad</Form.Label>
                                                <Form.Control type={"number"} value={quantity} onChange={handleQuantityChange}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Button style={{marginTop: "3.5vh"}} onClick={handleNewSaleDetail}>Registrar</Button>
                                        </Col>
                                    </Row>

                                    <Table striped bordered hover>
                                        <thead>
                                        <th>Id</th>
                                        <th>Producto</th>
                                        <th>Cantidad</th>
                                        <th>Precio Unidad</th>
                                        <th>Total</th>
                                        </thead>
                                        <tbody>
                                        {
                                            saleDetails.map((saleDetail, index) => (
                                                <tr key={index}>
                                                    <td>{saleDetail.productID}</td>
                                                    <td>{saleDetail.product_name}</td>
                                                    <td>{saleDetail.quantity}</td>
                                                    <td>{saleDetail.item_price}</td>
                                                    <td>{saleDetail.total}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                </>
                            )
                        }
                    </Tab.Pane>
                    <Tab.Pane eventKey="productos">
                        <h3>Tabla de Productos</h3>
                        <Table striped bordered hover>
                            <thead>
                                <th>Nombre</th>
                                <th>Descripcion</th>
                                <th>Cantidad</th>
                                <th>Costo</th>
                                <th>Precio Venta</th>
                            </thead>
                            <tbody>
                            {
                                productsTable.map((product, index) => (
                                    <tr key={index}>
                                        <td>{product.name}</td>
                                        <td>{product.description}</td>
                                        <td>{product.stock_quantity}</td>
                                        <td>{product.cost}</td>
                                        <td>{product.price}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </Tab.Pane>
                    <Tab.Pane eventKey="adminProductos">
                        <h3>Administracion de Productos</h3>
                        <Row>
                            <Col>
                                <Form.Label>Producto</Form.Label>
                                <select onChange={handleProductChange} className={"form-control"} value={product.id}>
                                    {
                                        productsTable.map((stock, index) => (
                                            <option key={index} value={stock.id}>
                                                {stock.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col>
                                <Form.Label>Cantidad</Form.Label>
                                <Form.Control type={"text"} value={product.stock_quantity + " Unidades"} disabled />
                            </Col>
                            <Col>
                                <Form.Label>Descripcion</Form.Label>
                                <Form.Control type={"text"} value={product.description} name={"description"} onChange={handleProductEdit}/>
                            </Col>
                            <Col>
                                <Form.Label>Costo</Form.Label>
                                <Form.Control type={"number"} value={product.cost} name={"cost"} onChange={handleProductEdit}/>
                            </Col>
                            <Col>
                                <Form.Label>Precio Venta</Form.Label>
                                <Form.Control type={"number"} value={product.price} name={"price"} onChange={handleProductEdit}/>
                            </Col>
                            <Col>
                                <Button onClick={handleSaveProduct} style={{marginTop: "3.5vh"}}>Guardar</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type={"text"} name={"name"} value={newProduct.name} onChange={handleNewProductChange}/>
                            </Col>
                            <Col>
                                <Form.Label>Descripcion</Form.Label>
                                <Form.Control type={"text"} value={newProduct.description} name={"description"} onChange={handleNewProductChange}/>
                            </Col>
                            <Col>
                                <Form.Label>Costo</Form.Label>
                                <Form.Control type={"number"} value={newProduct.cost} name={"cost"} onChange={handleNewProductChange}/>
                            </Col>
                            <Col>
                                <Form.Label>Precio Venta</Form.Label>
                                <Form.Control type={"number"} value={newProduct.price} name={"price"} onChange={handleNewProductChange}/>
                            </Col>
                            <Col>
                                <Button onClick={handleSaveNewProduct} style={{marginTop: "3.5vh"}}>Crear nuevo producto</Button>
                            </Col>
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="adminBodega">
                        <h3>Administracion de Stock</h3>
                        <Row>
                            <Col>
                                <select onChange={handleStockSelectChange} className={"form-control"} value={selectedStock.id}>
                                    {
                                        stocks.map((stock, index) => (
                                            <option key={index} value={stock.id}>
                                                {stock.product_name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col>
                                <Form.Control type={"text"} value={selectedStock.quantity + " Unidades"} disabled />
                            </Col>
                            <Col>
                                <Form.Control type={"number"} value={quantity} onChange={handleQuantityChange}/>
                            </Col>
                            <Col>
                                <Button onClick={handleAdd}>Sumar</Button>
                                <Button style={{marginLeft: "2vw"}} onClick={handleSubtract}>Restar</Button>
                            </Col>
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="repRotacion">
                        <h3>Reporte de Rotacion</h3>
                        <Table striped bordered hover>
                            <thead>
                            <th>Nombre</th>
                            <th>Descripcion</th>
                            <th>Productos vendidos</th>
                            <th>Total vendido</th>
                            <th>Total costos</th>
                            <th>Ganancia total</th>
                            </thead>
                            <tbody>
                            {
                                productsSale.map((product, index) => (
                                    <tr key={index}>
                                        <td>{product.product_name}</td>
                                        <td>{product.product_description}</td>
                                        <td>{product.total_sold}</td>
                                        <td>{product.total_revenue}</td>
                                        <td>{product.total_cost}</td>
                                        <td>{product.net_profit}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </Tab.Pane>
                    <Tab.Pane eventKey="repProductos">
                        <h3>Reporte de Productos en Stock</h3>
                        <Table striped bordered hover>
                            <thead>
                            <th>Nombre</th>
                            <th>Cantidad</th>
                            </thead>
                            <tbody>
                            {
                                productStockReport.map((product, index) => (
                                    <tr key={index}>
                                        <td>{product.product_name}</td>
                                        <td>{product.total_quantity}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </Tab.Pane>
                    <Tab.Pane eventKey="repVentasFecha">
                        <h3>Reporte de Ventas por fecha</h3>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Date:</Form.Label>
                                    <DatePicker
                                        selected={selectedStartDate}
                                        onChange={handleStartDateChange}
                                        dateFormat="yyyy-MM-dd"
                                        isClearable
                                        placeholderText="Fecha Inicio"
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Date:</Form.Label>
                                    <DatePicker
                                        selected={selectedEndDate}
                                        onChange={handleEndDateChange}
                                        dateFormat="yyyy-MM-dd"
                                        isClearable
                                        placeholderText="Fecha Fin"
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Button onClick={handleDateSearch}>Buscar</Button>
                            </Col>
                        </Row>
                        <Table striped bordered hover>
                            <thead>
                            <th>Usuario</th>
                            <th>Fecha</th>
                            <th>Total de costo</th>
                            <th>Total de venta</th>
                            <th>Total de ganancia</th>
                            </thead>
                            <tbody>
                            {
                                salesReport.map((product, index) => (
                                    <tr key={index}>
                                        <td>{product.username}</td>
                                        <td>{new Date(product.sale_date).toDateString()}</td>
                                        <td>{product.total_cost}</td>
                                        <td>{product.total_revenue}</td>
                                        <td>{product.total_profit}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </Tab.Pane>
                    <Tab.Pane eventKey="repVentasOperador">
                        <h3>Ventas por Operador</h3>
                        <Row>
                            <Col>
                                <select onChange={handleUserChange} className={"form-control"}>
                                    {
                                        users.map((user, index) => (
                                            <option key={index} value={user.id}>
                                                {user.username}
                                            </option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col>
                                <Button onClick={handleUserSalesSearch}>Buscar</Button>
                            </Col>
                        </Row>
                        <Table striped bordered hover>
                            <thead>
                            <th>Fecha</th>
                            <th>Total de costo</th>
                            <th>Total de venta</th>
                            <th>Total de ganancia</th>
                            </thead>
                            <tbody>
                            {
                                userSalesSummary.map((product, index) => (
                                    <tr key={index}>
                                        <td>{new Date(product.sale_date).toDateString()}</td>
                                        <td>{product.total_cost}</td>
                                        <td>{product.total_revenue}</td>
                                        <td>{product.total_profit}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </Tab.Pane>
                    <Tab.Pane eventKey="repVentas">
                        <h3>Mi reporte de ventas</h3>
                        <Table striped bordered hover>
                            <thead>
                            <th>Fecha</th>
                            <th>Total de costo</th>
                            <th>Total de venta</th>
                            <th>Total de ganancia</th>
                            </thead>
                            <tbody>
                            {
                                salesReportByOperator.map((product, index) => (
                                    <tr key={index}>
                                        <td>{new Date(product.sale_date).toDateString()}</td>
                                        <td>{product.total_cost}</td>
                                        <td>{product.total_revenue}</td>
                                        <td>{product.total_profit}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Container>
    );
};
export default Landing;