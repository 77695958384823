import React, {useEffect, useState} from "react";
import { Container, Card, Button, Form } from "react-bootstrap";
import {useApiService} from "../../hooks/useApiService";
import {useNavigate} from "react-router-dom";

const Inicio = ({setUsuario}) => {
    const { sendPOST, sendGET } = useApiService();
    const navigate = useNavigate();

    const [login, setLogin] = useState({
        username: "",
        password: "",
    })

    const handleInputChange = (e) => {
        setLogin(prevState => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            }
        })
    }

    const handleSubmit = () => {
        sendPOST("login", login).then((result) => {
            setUsuario(result["user"]);
            navigate('/home');
        }).catch(() => {
            navigate('/');
        });
    }

    const handleCargaInicial = () => {
        sendPOST("carga-inicial", null).then((result) => {
            navigate('/');
        }).catch(() => {
            navigate('/');
        });
    }

    const [cargarIniciales, setCargarIniciales] = useState(false)

    const getUsers = () => {
        sendGET("users").then((result) => {
            setCargarIniciales(result.length === 0);
        }).catch(() => {});
    }

    useEffect(() => {
        getUsers()
    }, []);

    return (
        <Container
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
        >
            <Card style={{height: "40vh", width: "30vw", padding: "2vh"}} >
                <Card.Title className="text-center">Inicio de Sesion</Card.Title>
                <Card.Body className="text-center d-flex align-items-center justify-content-center">
                    <Form>
                        <Form.Group>
                            <Form.Label>Usuario</Form.Label>
                            <Form.Control type="text" name="username" onChange={handleInputChange}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" name="password" onChange={handleInputChange}></Form.Control>
                        </Form.Group>
                        <Form.Group className={"mt-4"}>
                            <Button onClick={handleSubmit}>Ingresar</Button>
                            {
                                cargarIniciales && (
                                    <Button onClick={handleCargaInicial}>Cargar usuarios iniciales</Button>
                                )
                            }
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Inicio;
