export function useApiService() {

    const URL_BASE = "https://backendproy.onrender.com/";

    const sendRequest = async (method, endpoint, formData) => {
        const headers = {
            'Content-Type': 'application/json'
        };

        const requestOptions = {
            method: method,
            headers: headers,
        };

        // Si formData es válido, lo incluimos en la solicitud.
        if (formData) {
            requestOptions.body = JSON.stringify(formData);
        }

        const response = await fetch(URL_BASE + endpoint, requestOptions);

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error en la solicitud');
        }

        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return await response.json();
        } else {
            return {};
        }
    };

    const sendGET = async (endpoint) => {
        return sendRequest('GET', endpoint, null);
    };

    const sendPOST = async (endpoint, formData) => {
        return sendRequest('POST', endpoint, formData);
    };

    const sendPUT = async (endpoint, formData) => {
        return sendRequest('PUT', endpoint, formData);
    };

    const sendDELETE = async (endpoint) => {
        return sendRequest('DELETE', endpoint, null);
    };

    return { sendGET, sendPOST, sendPUT, sendDELETE };
}